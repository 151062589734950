import React from 'react';
import {Admin, Resource} from 'react-admin';
import {UserList, UserEdit, UserCreate} from './users'
import {CustomerList} from "./customers";
import UserIcon from '@material-ui/icons/Group';
import HotelIcon from '@material-ui/icons/Home';
import CustomerIcon from '@material-ui/icons/Face';
import BookingIcon from '@material-ui/icons/Event';
import MoneyIcon from '@material-ui/icons/Money';
import Dashboard from "./Dashboard";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import {AdminBookingEdit, AdminBookingList} from "./adminBooking";
import {ManagerBookingEdit, ManagerBookingList} from "./managerBooking";
import {AdminHotelsEdit, AdminHotelsList} from "./adminHotels";
import {ManagerHotelsList} from "./managerHotels";
import {BookingShow} from "./bookingShow";
import {HotelsShow} from "./hotelsShow";
import {ExtraChargesCreate, ExtraChargesEdit, ExtraChargesList} from "./extraCharges";
import {IsAdmin, IsManager} from "./roleChecker";
import {createMuiTheme} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import CalibriWoff2 from './fonts/Calibri.woff2';

const calibri = {
    fontFamily: 'Calibri',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
    local('Calibri'),
    local('Calibri'),
    url(${CalibriWoff2}) format('woff2')
  `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: blue,
    },
    typography: {
        fontFamily: 'Calibri, Calibri',
    },
    overrides: {
        MuiTableCell: {
            root: {
                borderRight: '1px solid rgb(191, 191, 191)',
                padding: '0px 10px 0px 10px !important',
            },
        },
        MuiCssBaseline: {
            '@global': {
                '@font-face': [calibri],
            },
        },
    },
});

const App = () => (
    <Admin authProvider={authProvider} dataProvider={dataProvider} dashboard={Dashboard} theme={theme}
           title="Бронювання">
        {permissions => [
            IsAdmin(permissions) ?
                <Resource name="users" options={{label: 'Працівники'}} list={UserList} edit={UserEdit}
                          create={UserCreate} icon={UserIcon}/> : null,
            IsAdmin(permissions) ?
                <Resource name="hotels" options={{label: 'Готелі'}} list={AdminHotelsList} edit={AdminHotelsEdit} icon={HotelIcon}/> : null,
            IsManager(permissions) ?
                <Resource name="hotels" options={{label: 'Готелі'}} list={ManagerHotelsList} show={HotelsShow} icon={HotelIcon}/> : null,
            IsAdmin(permissions) ?
                <Resource name="customers" options={{label: 'Туристи'}} list={CustomerList}
                          icon={CustomerIcon}/> : null,
            IsAdmin(permissions) ?
                <Resource name="bookings" options={{label: 'Бронювання'}} list={AdminBookingList}
                          show={BookingShow} edit={AdminBookingEdit} icon={BookingIcon}/> :
                <Resource name="bookings" options={{label: 'Бронювання'}} list={ManagerBookingList}
                          show={BookingShow} edit={ManagerBookingEdit} icon={BookingIcon}/>,
            IsAdmin(permissions) ?
                <Resource
                    name="extra-charges"
                    options={{label: 'Маржа'}}
                    list={ExtraChargesList}
                    edit={ExtraChargesEdit}
                    create={ExtraChargesCreate}
                    icon={MoneyIcon}/>
                : null,
            <Resource name="hotels/cities"/>,
            <Resource name="hotels/countries"/>,
            <Resource name="customers/cities"/>,
            <Resource name="bookings/guests/countries"/>,
        ]}
    </Admin>
);

export default App;