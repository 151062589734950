import React, {Component} from 'react';
import dataProvider from "../dataProvider";

class ToggleableEnabledStateField extends Component {
    state = this.initialState;

    get initialState() {
        const {record, resource, source} = this.props;

        return {
            checked: record[source],
            record: record,
            resource: resource,
        }
    }

    handleChange = () => {
        this.setState({checked: !this.state.checked});

        return dataProvider.changeEnabledState(this.state.record, this.state.resource).catch(() => this.setState({checked: !this.state.checked}));
    };

    render() {
        return (
            <input
                type="checkbox"
                checked={this.state.checked}
                onChange={this.handleChange}
            />
        );
    }
}

export default ToggleableEnabledStateField;