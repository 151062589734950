import React from 'react';
import {
    List,
    SearchInput,
    Edit,
    EditButton,
    SimpleForm,
    Datagrid,
    Filter,
    FunctionField,
    TextInput,
    DateInput,
    ReferenceInput,
    AutocompleteInput,
    TextField
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";
import beautyCountry from "./helpers/beautyCountryRow";

const ExcelJS = require('exceljs');

const countryRenderer = choice => (choice.name ? beautyCountry(choice.name) : null);

function convertDate(dateStr) {
    const dateObj = new Date(dateStr);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${day}.${month}.${year}`;
}

function convertDatetime(datetimeStr) {
    const dateObj = new Date(datetimeStr);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const time = datetimeStr.split('T')[1].split('+')[0];
    return `${day}.${month} ${time}`;
}

const AdminBookingFilter = props => (
    <Filter {...props}>
        <SearchInput source="reference" alwaysOn placeholder="Номер бронювання"/>
        <TextInput source="hotelTitle" label="Назва готеля"/>
        <DateInput source="createdAtFrom" label="Дата бронювання з"/>
        <DateInput source="createdAtTo" label="Дата бронювання до"/>
        <DateInput source="checkInFrom" label="Дата заїзду з" />
        <DateInput source="checkInTo" label="Дата заїзду до" />
        <DateInput source="checkOutFrom" label="Дата виїзду з" />
        <DateInput source="checkOutTo" label="Дата виїзду до" />
        <TextInput source="customer" label="Власник аккаунту"/>
        <ReferenceInput label="Країна гостя" source="guest_country" reference="bookings/guests/countries"
                        filterToQuery={searchText => ({term: searchText})}>
            <AutocompleteInput optionText={countryRenderer} helperText={false}/>
        </ReferenceInput>
    </Filter>
);

const exportData = posts => {
    const workBook = new ExcelJS.Workbook();
    const sheet = workBook.addWorksheet('My sheet');

    sheet.columns = [
        {
            header: 'Номер бронювання',
            key: 'hotelBookingUniqueNumber',
            width: 15
        },
        {
            header: 'Назва готеля',
            key: 'hotelTitle',
            width: 15
        },
        {
            header: 'Дата бронювання',
            key: 'createdAt',
            width: 20
        },
        {
            header: 'Дата заїзду',
            key: 'checkIn',
            width: 15
        },
        {
            header: 'Дата виїзду',
            key: 'checkOut',
            width: 15
        },
        {
            header: 'Діб',
            key: 'totalNights',
            width: 10
        },
        {
            header: 'Власник аккаунту',
            key: 'customerName',
            width: 20
        },
        {
            header: 'Гість з країни',
            key: 'customerCountry',
            width: 15
        },
        {
            header: 'Гість з міста',
            key: 'customerCity',
            width: 15
        },
        {
            header: 'Будуть жити у номері',
            key: 'guestsCount',
            width: 15
        },
        {
            header: 'Сумма проживання, грн.',
            key: 'totalPrice',
            width: 15
        },
        {
            header: 'Статус бронювання',
            key: 'bookingStatus',
            width: 15
        }
    ];

    posts.forEach(post => {
        sheet.addRow({
            hotelBookingUniqueNumber: post.details[0] ? post.details[0].hotelBookingUniqueNumber : '',
            hotelTitle: post.hotel ? post.hotel.title : '',
            createdAt: convertDatetime(post.created_at),
            checkIn: convertDate(post.check_in),
            checkOut: convertDate(post.check_out),
            totalNights: post.details[0] ? post.details[0].totalNights : '',
            customerName: post.customer ? `${post.customer.name} ${post.customer.surname} ${(post.country_code ? beautyCountry(post.country_code) : '')}` : null,
            customerCountry: `${(post.customer.country ? beautyCountry(post.customer.country) : '')}`,
            customerCity: `${(post.customer.city ? beautyCountry(post.customer.city) : '')}`,
            guestsCount: post.details[0] ? post.details[0].hotelBookingInfo[0].hotelBookingGuestsNumber : '',
            totalPrice: post.details[0] ? post.details[0].hotelBookingTotalPrice : '',
            bookingStatus: post.status
        });
    });

    workBook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheet.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'Booking.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(url);
    });
}


const reference = record => record.details[0] ? record.details[0].hotelBookingUniqueNumber : '';
const hotelTitle = record => record.hotel ? record.hotel.title : '';
const guestsInRoom = record => record.details[0] ? record.details[0].hotelBookingInfo[0].hotelBookingGuestsNumber : '';
const bookingStatus = record => record.status;
const totalNights = record => record.details[0] ? record.details[0].totalNights : '';
const hotelBookingTotalPrice = record => record.details[0] ? record.details[0].hotelBookingTotalPrice : '';
const customerRenderer = record => record.customer ? `${record.customer.name} ${record.customer.surname} ${(record.country_code ? beautyCountry(record.country_code) : '')}` : null;
const createdAt = record => convertDatetime(record.created_at);
const checkIn = record => convertDate(record.check_in);
const checkOut = record => convertDate(record.check_out);

export const AdminBookingList = props => (
    <List filters={<AdminBookingFilter/>} {...props} exporter={exportData} >
        <Datagrid rowClick="show">
            <FunctionField source="details.hotelBookingUniqueNumber" label="Номер бронювання" render={reference} />
            <FunctionField source="hotel.title" label="Назва готеля" render={hotelTitle} />
            <FunctionField source="createdAt" render={createdAt} label="Дата бронювання" />
            <FunctionField source="checkIn" label="Дата заїзду" render={checkIn} />
            <FunctionField source="checkOut" label="Дата виїзду" render={checkOut} />
            <FunctionField source="details.totalNights" label="Діб" render={totalNights} />
            <FunctionField source='customer.name' label="Власник аккаунту" render={customerRenderer} />
            <FunctionField source="customer.country" label="Гість з країни"
                           render={record => `${(record.customer.country ? beautyCountry(record.customer.country) : '')}`}/>
            <FunctionField source="customer.city" label="Гість з міста"
                           render={record => `${(record.customer.city ? beautyCountry(record.customer.city) : '')}`}/>
            <FunctionField source='details.hotelBookingInfo.hotelBookingGuestsNumber' label="Будуть жити у номері" render={guestsInRoom}/>
            <FunctionField source="details.hotelBookingTotalPrice" label="Сумма проживання, грн." render={hotelBookingTotalPrice}/>
            <FunctionField source="status" label="Статус бронювання" render={bookingStatus}/>
            <TextField source="customer.email" label="Мейл власника"/>
            <TextField source="customer.phone" label="Телефон власника"/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const AdminBookingEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <RichTextInput source="note" label="Примітки"/>
        </SimpleForm>
    </Edit>
);
