import React from 'react';
import {
    List,
    Edit,
    Create,
    Datagrid,
    SimpleForm,
    TextField,
    BooleanField,
    TextInput,
    NumberInput,
    BooleanInput,
    EditButton,
    required
} from 'react-admin';
import DirectorySelectInput from "./input/DirectorySelectInput";

export const ExtraChargesList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="">
            <TextField source="title" label="Назва"/>
            <TextField source="value" label="Значення"/>
            <TextField source="algorithm" label="Алгоритм"/>
            <BooleanField source="default" label="За замовчуванням"/>
            <TextField source="hotels_count" label="Кількість готелів"/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const ExtraChargesEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="title" label="Назва"/>
            <NumberInput source="value" label="Значення" validate={required()}/>
            <DirectorySelectInput source="algorithm" label="Алгоритм" dirname="extra_charge_algorithms" validate={required()}/>
            <BooleanInput source="default" label="За замовчуванням"/>
        </SimpleForm>
    </Edit>
);

export const ExtraChargesCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="title" label="Назва"/>
            <NumberInput source="value" label="Значення" validate={required()}/>
            <DirectorySelectInput source="algorithm" label="Алгоритм" dirname="extra_charge_algorithms" validate={required()}/>
            <BooleanInput source="default" label="За замовчуванням"/>
        </SimpleForm>
    </Create>
);
