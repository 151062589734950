import dataProvider from "./dataProvider";
import CountriesList from "./countriesList";

let localDirectories = {
    'countries': CountriesList
};

let directories = {};

let request = {
    sent: false,
    promise: null
};

const extractDirectory = (name) => {
    return directories[name] ? directories[name] : [];
};

const loadDirectories = () => {
    if (request.sent === true) {
        return request.promise;
    }

    request.sent = true;
    return request.promise = dataProvider.getStaticDirectories()
        .then(
            (data) => {
                directories = Object.assign(data, localDirectories);
                request.sent = false;
            }
        );
};

const getDirectory = (name) => {
    if (Object.entries(directories).length !== 0) {
        return new Promise((resolve) => {
            resolve();
        }).then(() => {
            return extractDirectory(name);
        });
    }

    return loadDirectories()
        .then(() => {
            return extractDirectory(name)
        });
};

export default getDirectory;