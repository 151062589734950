import React from 'react';
import {
    List,
    Edit,
    Create,
    Datagrid,
    SimpleForm,
    TextField,
    TextInput,
    PasswordInput,
    EditButton,
    required
} from 'react-admin';
import DirectorySelectArrayInput from "./input/DirectorySelectArrayInput";
import ArrayListField from "./fields/ArrayListField";

const validateUserForm = (values) => {
    const errors = {};
    if (values.plainPassword && values.repeatPassword && values.plainPassword !== values.repeatPassword) {
        errors.plainPassword = ['The passwords doesn\'t matches'];
    }
    return errors
};

export const UserList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="">
            <TextField source="username"/>
            <ArrayListField source="roles"/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const UserEdit = props => (
    <Edit {...props}>
        <SimpleForm validate={validateUserForm}>
            <TextInput source="username" validate={required()}/>
            <PasswordInput source="plainPassword" label={"Password"}/>
            <PasswordInput source="repeatPassword"/>
            <DirectorySelectArrayInput source="roles" dirname="roles" validate={required()}/>
        </SimpleForm>
    </Edit>
);

export const UserCreate = props => (
    <Create {...props}>
        <SimpleForm validate={validateUserForm} redirect="list">
            <TextInput source="username" validate={required()}/>
            <PasswordInput source="plainPassword" label={"Password"} validate={required()}/>
            <PasswordInput source="repeatPassword" validate={required()}/>
            <DirectorySelectArrayInput source="roles" dirname="roles" validate={required()}/>
        </SimpleForm>
    </Create>
);
