import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    ShowButton,
    Filter,
    ReferenceInput,
    DateInput,
    FunctionField,
    AutocompleteInput,
    Pagination
} from 'react-admin';
import ToggleableEnabledStateField from "./fields/TogglableField";
import DirectorySelectInput from "./input/DirectorySelectInput";
import beautyCountry from "./helpers/beautyCountryRow";

const countryRenderer = choice => (choice.name ? beautyCountry(choice.name) : null);

console.log('managerHotels');
const ManagerHotelsFilter = props => (
    <Filter {...props}>
        <TextInput source="title" label="Назва" alwaysOn/>
        <DirectorySelectInput source="type" dirname="hotel_types" label="Тип"/>
        <DirectorySelectInput source="provider" dirname="providers" label="Провайдер"/>
        <DateInput source="externalCreatedAt" label="Дата реєстрації готеля"/>
        <DirectorySelectInput source="stars" dirname="stars" label="Зірки"/>
        <ReferenceInput label="Місто" source="city" reference="hotels/cities"
                        filterToQuery={searchText => ({term: searchText})}>
            <AutocompleteInput optionText="name" helperText={false}/>
        </ReferenceInput>
        <ReferenceInput label="Країна" source="countryCode" reference="hotels/countries"
                        filterToQuery={searchText => ({term: searchText})}>
            <AutocompleteInput optionText={countryRenderer} helperText={false}/>
        </ReferenceInput>
    </Filter>
);


const ManagerHotelsPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const ManagerHotelsList = ({permissions, ...props}) => (
    <List filters={<ManagerHotelsFilter/>} pagination={<ManagerHotelsPagination />} {...props} exporter={false} bulkActionButtons={false}>
        <Datagrid rowClick="show" isRowSelectable={() => false}>
            <TextField source="title" label="Назва"/>
            <TextField source="type" label="Тип"/>
            <TextField source="stars" label="Зірки"/>
            <FunctionField
                render={record => (record.country_code ? beautyCountry(record.country_code) : null)}
                label="Країна"/>
            <TextField source="city" label="Місто"/>
            <TextField source="address" label="Адреса"/>
            {/* <ContactsField source="contacts" label="Мейл, Телефон"/> */}
            {/*<TextField source="contact_person" label="Особа"/>*/}
            <ToggleableEnabledStateField source="enabled" label="Якщо є 'галочка', то готель опубліковано на сайті" />
            <TextField source="provider" label="Провайдер"/>
            <FunctionField source="externalCreatedAt"
                           render={record => (record.external_created_at ? (new Date(record.external_created_at)).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' }) : null)}
                           label="Дата реєстрації готеля"/>
            <FunctionField
                render={record => (record.hotel_insite_contacts[0] ? record.hotel_insite_contacts[0].phoneNumber : null)}
                label="Sales phone"/>
            <FunctionField
                render={record => (record.hotel_insite_contacts[0] ? record.hotel_insite_contacts[0].email : null)}
                label="Sales e-mail"/>
            <ShowButton label="Show"/>
        </Datagrid>
    </List>
);

