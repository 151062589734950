import React from 'react';

const ArrayListField = ({source, record}) => (
    <ul>
        {record[source].map(value => (
            <li key={value}>{value}</li>
        ))}
    </ul>
);

export default ArrayListField;