import React from 'react';
import {
    Error,
    Loading,
    useQuery
} from 'react-admin';

const CustomShow = props => {
    const {data, loading, error} = useQuery({
        type: 'getOneShow',
        resource: 'hotels',
        payload: {id: props.id}
    });
    if (loading) return <Loading/>;
    if (error) return <Error/>;
    if (!data) return null;
    return (
        <div>
            <div><strong>Hotel id:</strong> {data.id}</div>
            <div><strong>Title:</strong> {data.title}</div>
            <div><strong>Provider:</strong> {data.provider}</div>
            <div><strong>City:</strong> {data.city}</div>
            <div><strong>Address:</strong> {data.address}</div>
            <div><strong>Stars:</strong> {data.stars}</div>
            <div><strong>Created:</strong> {data.external_created_at}</div>
        </div>
    );
};

export const HotelsShow = (props) => (
    <CustomShow {...props}>
    </CustomShow>
);