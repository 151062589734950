import React from 'react';
import {TextField} from 'react-admin'

const ContactsField = ({source, record, ...rest}) => (
    record && null != record[source]
        ?
        <div>
            <TextField label="dsf email" source={`${source}.email`} record={record} {...rest}/>
            <br></br>
            <TextField source={`${source}.phone`} record={record} {...rest} />
        </div>
        : null
);

export default ContactsField;