import {fetchUtils} from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(process.env.REACT_APP_BASE_API_URL, httpClient);

dataProvider.changeEnabledState = (params, resourceName) => {
    return httpClient(process.env.REACT_APP_BASE_API_URL + "/" + resourceName + "/" + params.id + "/enabled", {
        method: 'PATCH',
    }).then(() => {
        return true;
    });
};

dataProvider.getStaticDirectories = () => {
    return httpClient(process.env.REACT_APP_BASE_API_URL + "/directories/static", {
        method: 'GET',
    }).then(function (_a) {
        return _a.json;
    });
};

dataProvider.getOneShow = (resource, params) => {
    return httpClient(process.env.REACT_APP_BASE_API_URL + "/" + resource + "/" + params.id + "/show").then(function (_a) {
        var json = _a.json;
        return ({
            data: json,
        });
    });
};

export default dataProvider;