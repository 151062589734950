import React, {useState, useEffect} from 'react';
import {AutocompleteInput, Loading, Error} from 'react-admin';
import getDirectory from "../staticDirectoryCache";

const DirectoryAutocompleteInput = ({dirname, ...props}) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        getDirectory(dirname)
            .then((data) => {
                setOptions(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [dirname]);

    if (loading) return <Loading/>;
    if (error) return <Error/>;
    if (!options) return null;

    return (
        <AutocompleteInput
            choices={options}
            {...props}
        />
    );
};

export default DirectoryAutocompleteInput;