import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    FunctionField,
    TextInput,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';
import CustomerBookingUrlField from "./fields/CustomerBookingUrlField";
import DirectorySelectInput from "./input/DirectorySelectInput";
import DirectoryAutocompleteInput from "./input/DirectoryAutocompleteInput";
import beautyCountry from "./helpers/beautyCountryRow";
import CurrencyField from "./fields/CurrencyField";

const ExcelJS = require('exceljs');

const CustomerFilter = props => (
    <Filter {...props}>
        <TextInput source="name" alwaysOn/>
        <TextInput source="surname" alwaysOn/>
        <DirectorySelectInput source="gender" dirname="genders"/>
        <DirectoryAutocompleteInput source="country" dirname="countries"/>
        <ReferenceInput label="City" source="city" reference="customers/cities"
                        filterToQuery={searchText => ({term: searchText})}>
            <AutocompleteInput optionText="name" helperText={false}/>
        </ReferenceInput>
    </Filter>
);

const exportData = posts => {
    const workBook = new ExcelJS.Workbook();
    const sheet = workBook.addWorksheet('My sheet');

    sheet.columns = [
        {
            header: "Ім'я",
            key: 'name',
            width: 20
        },
        {
            header: 'Країна',
            key: 'country',
            width: 20
        },            {
            header: 'E-mail',
            key: 'email',
            width: 30
        },            {
            header: 'Телефон',
            key: 'phone',
            width: 20
        },            {
            header: 'Стать',
            key: 'gender',
            width: 10
        },
        {
            header: 'Заброньовано, грн.',
            key: 'bookedAmount',
            width: 20
        }
    ];

    posts.forEach(post => {
        sheet.addRow({
            name: `${post.name} ${post.surname}`,
            country: `${post.city} (${post.country ? beautyCountry(post.country) : ''})`,
            email: post.email,
            phone: post.phone,
            gender: post.gender,
            bookedAmount: post.booked_amount
        });
    });

    workBook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheet.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'Customers.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(url);
    });
}

export const CustomerList = props => (
    <List filters={<CustomerFilter/>} {...props} exporter={exportData}>
        <Datagrid rowClick="">
            <FunctionField label="Ім'я" render={record => `${record.name} ${record.surname}`}/>
            <FunctionField label="Країна"
                           render={record => `${record.city} (${record.country ? beautyCountry(record.country) : ''})`}/>
            <TextField source="email" label="E-mail"/>
            <TextField source="phone" label="Телефон"/>
            <TextField source="gender" label="Стать"/>
            <CurrencyField source="booked_amount" label="Заброньовано, грн."/>
            <CustomerBookingUrlField label="Бронювання клієнта"/>
        </Datagrid>
    </List>
);