import React from 'react';
import {
    Error,
    Loading,
    useQuery
} from 'react-admin';

const BEDS = {
    "roomBedOneSize": "Односпальне ліжко (90-119см)",
    "roomBedHalfSize": "Полуторне ліжко (120-140см)",
    "roomBedTwoSize": "Двоспальне ліжко (141-169см)",
    "roomBedQueenSize": "Велике двоспальне ліжко (170-199см)",
    "roomBedKingSize": "Дуже велике двоспальне ліжко (200см+)",
    "roomBedBinbed": "Двоярусне ліжко",
    "roomBedSofaOne": "Диван (крісло) розкладний односпальний",
    "roomBedSofaTwo": "Диван (крісло) розкладний двоспальний",
    "roomBedSofaHalf": "Диван (софа) не розкладна односпальна",
    "roomBedFuton": "Футон",
    "roomBedSleepPlace": "Койкомісце",
    "roomBedSleepPlaceBed": "Койкомісце на двоярусному ліжку",
    "roomBedOneDoubleOrTwoSeparate": "Одне двоспальне або два окремих ліжка"
}  

const CustomShow = props => {
    const {data, loading, error} = useQuery({
        type: 'getOneShow',
        resource: 'bookings',
        payload: {id: props.id}
    });
    if (loading) return <Loading/>;
    if (error) return <Error/>;
    if (!data) return null;

    return (
        <div>
            <h2>Назва готелю: {data.hotel.title}</h2>
            <h3>Адреса готелю: {data.hotel.address}</h3>
            <div>
                <strong>Email:</strong> {data.details[0] ? data.details[0].hotel.hotelInsiteContacts[0].email : ''}
            </div>
            <div>
                <strong>Phone:</strong> {data.details[0] ? data.details[0].hotel.hotelInsiteContacts[0].phoneNumber : ''}
            </div>
            <div><strong>Дата та час бронювання:</strong> {data.details[0] ? data.details[0].dateOfBooking : ''}</div>
            {data.cancellation_date && (<div><strong>Дата та час відміни бронювання:</strong> {data.cancellation_date}</div>)}
            {data.details.length > 1 && (<div><strong>Дата та час зміни бронювання:</strong> {data.details[data.details.length - 1].lastUpdatedAt}</div>)}
            <div><strong>Дата заїзду:</strong> {data.details.length > 0 ? data.details[data.details.length - 1].dateCheckIn : ''} {data.details.length > 1 && (<span><strong>(було:</strong> {data.details[data.details.length - 2].dateCheckIn})</span>)}</div>
            <div><strong>Дата виїзду:</strong> {data.details.length > 0 ? data.details[data.details.length - 1].dateCheckOut : ''} {data.details.length > 1 && (<span><strong>(було:</strong> {data.details[data.details.length - 2].dateCheckOut})</span>)}</div>
            <div><strong>Кількість ночей:</strong> {data.details.length > 0 ? data.details[data.details.length - 1].totalNights : ''} {data.details.length > 1 && data.details[data.details.length - 1].totalNights !== data.details[data.details.length - 2].totalNights && 
                (<span><strong>(було:</strong> {data.details[data.details.length - 2].totalNights} ночей)</span>)
            }</div>
            <div><strong>Вартість всього проживання:</strong> {data.details.length > 0 ? data.details[data.details.length - 1].hotelBookingTotalPrice : '0'} грн {data.details.length > 1 && data.details[data.details.length - 1].hotelBookingTotalPrice !== data.details[data.details.length - 2].hotelBookingTotalPrice && 
                (<span>(<strong>було:</strong> {data.details[data.details.length - 2].hotelBookingTotalPrice} грн)</span>)
            }</div>
            <div><strong>Кількість номерів:</strong> {data.details.length > 0 ? data.details[data.details.length - 1].hotelBookingInfo.length : ''}</div>
            <h3>Номери: </h3>
            {data.details.length > 0 && data.details[data.details.length - 1].hotelBookingInfo.map((room, index) => (
                <div>
                    {index > 0 && (<br/>)}
                    <div key={index}><strong>Номер {index+1}: </strong></div>
                    <br/>
                    <div>Гості:</div>
                    <ul>
                        {data.details.length > 0 && room.hotelBookingGuests.map((guest, index) => (
                            <li key={index}> {guest.lastName} {guest.firstName}</li>
                        ))}
                    </ul>
                    <div>Ліжка:</div>
                    <ul>
                        {data.details.length > 0 && room.hotelBookingRoom.hotelBeds.map((bed, index) => (
                            <li key={index}><strong>{bed.hotelRoomBedQuantity} </strong> - {BEDS[bed.hotelRoomBedType]}</li>
                        ))}
                    </ul>
                    <div><strong>Кількість дорослих:</strong> {data.tariffs[index].adults}</div>
                    <div><strong>Кількість дітей:</strong> {data.tariffs[index].children}</div>
                    <div><strong>Загальна вартість проживання у номері: </strong> 
                        {room.hotelBookingPrices[room.hotelBookingPrices.length - 1].priceFinal} грн
                    </div>
                    <div><strong>Харчування у тарифі:</strong> {room.hotelBookingTariff.hotelTariffTitle}</div>
                    {room.hotelBookingTariff.hotelCancellationPolicy.type === "hotelCancellationPolicyStandard" && 
                    (<div><strong>Політика відміни у цьому тарифі: </strong>
                        Стандартний тариф. За {room.hotelBookingTariff.hotelCancellationPolicy.daysBeforeCheckInNoPenalty} діб до дати заїзду, відміна бронювання безкоштовна. Після цього терміну, з вас буде утримано {room.hotelBookingTariff.hotelCancellationPolicy.penaltyPercent}% від вартості 
                        {room.hotelBookingTariff.hotelCancellationPolicy.typeOfPenaltyPercent === 'firstday' ? ' першої доби проживання.' : ' всього проживання.'}
                        </div>)}
                    {room.hotelBookingTariff.hotelCancellationPolicy.type !== "hotelCancellationPolicyStandard" &&
                    (<div><strong>Політика відміни у цьому тарифі: </strong>
                        {data.hotel.provider === 'extranet' && ('Безповоротний тариф. Тариф передбачає, що після бронювання, об\'єкт проживання витребуватиме повну передоплату замовлення. У разі зміни, відміни бронювання або незаїзду буде утриманно повну вартість бронювання.')}
                        {data.hotel.provider !== 'extranet' && ('Неповоротній тариф. 100% передоплата. Після оплати відміна та/або зміни не можливі. Оплата поверненню не підлягає')}
                        </div>)}
                    {data.details[data.details.length - 1].hotel.hotelTax.type === 'hotelTaxNotIncluded' &&
                    (<div><strong>Туристичний збір: </strong>
                        {data.details[data.details.length - 1].hotel.hotelTax.byPerson && (`Туристичний збір у розмірі ${data.details[data.details.length - 1].hotel.hotelTax.amount} ${data.details[data.details.length - 1].hotel.hotelTax.percent ? '%' : 'грн'} за особу, за добу, не входить у вартість проживання. Має бути сплачений при заїзді.`)}
                        {!data.details[data.details.length - 1].hotel.hotelTax.byPerson && (`Туристичний збір у розмірі ${data.details[data.details.length - 1].hotel.hotelTax.amount} ${data.details[data.details.length - 1].hotel.hotelTax.percent ? '%' : 'грн'} від вартості проживання не входить у вартість проживання. Має бути сплачений при заїзді.`)}
                        </div>)}
                </div>
            ))}

            {data.details.length > 0 && data.details[data.details.length - 1].hotel.hotelChildPolicy.accommodationData.length > 0 && (<h3>Умови розміщення дітей: </h3>)}
            {data.details.length > 0 && data.details[data.details.length - 1].hotel.hotelChildPolicy.accommodationData.map((childData, index) => (
                <div>{index+1}: <strong>Від {childData.yearsFrom} до {childData.yearsTo} років</strong> - {childData.price} грн та {childData.bedType}</div>
            ))}
            <br/>
            <div><strong>Додаткові ліжка для дорослих:</strong> {data.hotel.hotel_add_bed_request ? `Встановлення додаткових ліжок для дорослих потребує окремого запиту. Вартість ${data.hotel.hotel_add_bed_request_price} грн.` : 'Встановлення додаткових ліжок для дорослих неможливе.'}</div>
        </div>
    );
};

export const BookingShow = (props) => (
    <CustomShow {...props}>
    </CustomShow>
);