export const IsAdmin = (roles) => {
    if (!roles) {
        return false;
    }

    if (roles.toString() === 'ROLE_ADMIN') {
        return roles.includes('ROLE_ADMIN');
    }
};
export const IsManager = (roles) => {
    if (!roles) {
        return false;
    }
    if (roles.toString() === 'ROLE_MANAGER') {
        return roles.includes('ROLE_MANAGER')
    }
};