import React from 'react';

function currencyFormat(value) {
    let i = parseInt(value = Math.abs(Number(value/100) || 0).toFixed(2)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return [
        (j ? i.substr(0, j) + ' ' : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1 ") + ' грн.',
        Math.abs(value - i).toFixed(2).slice(2) + ' коп.'
    ];
}


const CurrencyField = ({source, record, render}) => {
    const values = currencyFormat(render ? render(record) : record[source]);
    return <div>
        <span className="nowrap">{values[0]} </span> <span className="nowrap">{values[1]}</span>
    </div>
}
CurrencyField.defaultProps = {
    addLabel: true
}
export default CurrencyField;