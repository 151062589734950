import React from 'react';
import {
    List,
    Edit,
    SimpleForm,
    Datagrid,
    TextField,
    Filter,
    FunctionField,
    TextInput,
    DateField, SearchInput, DateInput, ReferenceInput, AutocompleteInput, ShowButton
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";
import beautyCountry from "./helpers/beautyCountryRow";

const countryRenderer = choice => (choice.name ? beautyCountry(choice.name) : null);
const customerRenderer = record => record.customer ? `${record.customer.name} ${record.customer.surname} ${(record.country_code ? beautyCountry(record.country_code) : '')}` : null;
const guestsInRoom = record => record.details[0] ? record.details[0].hotelBookingInfo[0].hotelBookingGuestsNumber : '';
const hotelBookingTotalPrice = record => record.details[0] ? record.details[0].hotelBookingTotalPrice : '';
const bookingStatus = record => record.status;
const reference = record => record.details[0] ? record.details[0].hotelBookingUniqueNumber : '';
const hotelTitle = record => record.hotel ? record.hotel.title : '';

const ManagerBookingFilter = props => (
    <Filter {...props}>
        <SearchInput source="reference" alwaysOn placeholder="Номер бронювання"/>
        <TextInput source="hotelTitle" label="Назва готеля"/>
        <DateInput source="createdAt" label="Дата бронювання"/>
        <DateInput source="checkIn" label="Дата заїзду"/>
        <DateInput source="checkOut" label="Дата виїзду"/>
        <TextInput source="customer" label="Власник аккаунту"/>
        <ReferenceInput label="Країна гостя" source="guest_country" reference="bookings/guests/countries"
                        filterToQuery={searchText => ({term: searchText})}>
            <AutocompleteInput optionText={countryRenderer} helperText={false}/>
        </ReferenceInput>
    </Filter>
);

export const ManagerBookingList = props => (
    <List filters={<ManagerBookingFilter/>} exporter={false} {...props} bulkActionButtons={false}>
        <Datagrid rowClick="show" isRowSelectable={() => false}>
            <FunctionField source="details.hotelBookingUniqueNumber" label="Номер бронювання" render={reference} />
            <FunctionField source="hotel.title" label="Назва готеля" render={hotelTitle} />
            {/*<TextField source="reference" label="Номер бронювання"/>*/}
            <DateField source="created_at" label="Дата бронювання" showTime/>
            <TextField source="check_in" label="Дата заїзду"/>
            <TextField source="check_out" label="Дата виїзду"/>
            <TextField source="nights" label="Ночей"/>
            <FunctionField label="Проживає гість" render={record => `${record.customer.name} ${record.customer.surname}`}/>
            <FunctionField label="Гість із країни"
                           render={record => `${(record.customer.country ? beautyCountry(record.customer.country) : '')}`}/>
            <FunctionField source="customer.city" label="Гість з міста"
                           render={record => `${(record.customer.city ? beautyCountry(record.customer.city) : '')}`}/>
            <FunctionField label="Власник аккаунту" render={customerRenderer}/>
            <FunctionField source='details.hotelBookingInfo.hotelBookingGuestsNumber' label="Будуть жити у номері" render={guestsInRoom}/>
            <FunctionField source="details.hotelBookingTotalPrice" label="Сумма проживання, грн." render={hotelBookingTotalPrice}/>
            <FunctionField source="status" label="Статус бронювання" render={bookingStatus}/>
            <TextField source="customer.email" label="Мейл власника"/>
            <TextField source="customer.phone" label="Телефон власника"/>
            <ShowButton label="Show"/>
        </Datagrid>
    </List>
);

export const ManagerBookingEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <RichTextInput source="note" label="Примітки"/>
        </SimpleForm>
    </Edit>
);