const CountriesList = [{"id": "AF", "name": "Afghanistan"}, {"id": "AL", "name": "Albania"}, {
    "id": "DZ",
    "name": "Algeria"
}, {"id": "AD", "name": "Andorra"}, {"id": "AO", "name": "Angola"}, {"id": "AR", "name": "Argentina"}, {
    "id": "AM",
    "name": "Armenia"
}, {"id": "AW", "name": "Aruba"}, {"id": "AU", "name": "Australia"}, {"id": "AT", "name": "Austria"}, {
    "id": "AZ",
    "name": "Azerbaijan"
}, {"id": "BH", "name": "Bahrain"}, {"id": "BD", "name": "Bangladesh"}, {"id": "BY", "name": "Belarus"}, {
    "id": "BE",
    "name": "Belgium"
}, {"id": "BZ", "name": "Belize"}, {"id": "BJ", "name": "Benin"}, {"id": "BT", "name": "Bhutan"}, {
    "id": "BO",
    "name": "Bolivia"
}, {"id": "BA", "name": "Bosnia And Herzegovina"}, {"id": "BW", "name": "Botswana"}, {
    "id": "BR",
    "name": "Brazil"
}, {"id": "IO", "name": "British Indian Ocean Territory"}, {"id": "BN", "name": "Brunei Darussalam"}, {
    "id": "BG",
    "name": "Bulgaria"
}, {"id": "BF", "name": "Burkina Faso"}, {"id": "BI", "name": "Burundi"}, {"id": "KH", "name": "Cambodia"}, {
    "id": "CM",
    "name": "Cameroon"
}, {"id": "CV", "name": "Cape Verde"}, {"id": "CF", "name": "Central African Republic"}, {
    "id": "TD",
    "name": "Chad"
}, {"id": "CL", "name": "Chile"}, {"id": "CN", "name": "China"}, {"id": "CO", "name": "Colombia"}, {
    "id": "KM",
    "name": "Comoros"
}, {"id": "CG", "name": "Congo"}, {"id": "CD", "name": "Congo, Democratic Republic"}, {
    "id": "CK",
    "name": "Cook Islands"
}, {"id": "CR", "name": "Costa Rica"}, {"id": "CI", "name": "Cote D\u0027Ivoire"}, {
    "id": "HR",
    "name": "Croatia"
}, {"id": "CU", "name": "Cuba"}, {"id": "CY", "name": "Cyprus"}, {"id": "CZ", "name": "Czech Republic"}, {
    "id": "DK",
    "name": "Denmark"
}, {"id": "DJ", "name": "Djibouti"}, {"id": "EC", "name": "Ecuador"}, {"id": "EG", "name": "Egypt"}, {
    "id": "SV",
    "name": "El Salvador"
}, {"id": "GQ", "name": "Equatorial Guinea"}, {"id": "ER", "name": "Eritrea"}, {
    "id": "EE",
    "name": "Estonia"
}, {"id": "ET", "name": "Ethiopia"}, {"id": "FK", "name": "Falkland Islands (Malvinas)"}, {
    "id": "FO",
    "name": "Faroe Islands"
}, {"id": "FJ", "name": "Fiji"}, {"id": "FI", "name": "Finland"}, {"id": "FR", "name": "France"}, {
    "id": "PF",
    "name": "French Polynesia"
}, {"id": "GA", "name": "Gabon"}, {"id": "GM", "name": "Gambia"}, {"id": "GE", "name": "Georgia"}, {
    "id": "DE",
    "name": "Germany"
}, {"id": "GH", "name": "Ghana"}, {"id": "GI", "name": "Gibraltar"}, {"id": "GR", "name": "Greece"}, {
    "id": "GL",
    "name": "Greenland"
}, {"id": "GT", "name": "Guatemala"}, {"id": "GN", "name": "Guinea"}, {
    "id": "GW",
    "name": "Guinea-Bissau"
}, {"id": "GY", "name": "Guyana"}, {"id": "HT", "name": "Haiti"}, {"id": "HN", "name": "Honduras"}, {
    "id": "HK",
    "name": "Hong Kong"
}, {"id": "HU", "name": "Hungary"}, {"id": "IS", "name": "Iceland"}, {"id": "IN", "name": "India"}, {
    "id": "ID",
    "name": "Indonesia"
}, {"id": "IR", "name": "Iran, Islamic Republic Of"}, {"id": "IQ", "name": "Iraq"}, {
    "id": "IE",
    "name": "Ireland"
}, {"id": "IL", "name": "Israel"}, {"id": "IT", "name": "Italy"}, {"id": "JP", "name": "Japan"}, {
    "id": "JO",
    "name": "Jordan"
}, {"id": "KP", "name": "KP"}, {"id": "KE", "name": "Kenya"}, {"id": "KI", "name": "Kiribati"}, {
    "id": "KR",
    "name": "Korea"
}, {"id": "KW", "name": "Kuwait"}, {"id": "KG", "name": "Kyrgyzstan"}, {
    "id": "LA",
    "name": "Lao People\u0027s Democratic Republic"
}, {"id": "LV", "name": "Latvia"}, {"id": "LB", "name": "Lebanon"}, {"id": "LS", "name": "Lesotho"}, {
    "id": "LR",
    "name": "Liberia"
}, {"id": "LY", "name": "Libyan Arab Jamahiriya"}, {"id": "LI", "name": "Liechtenstein"}, {
    "id": "LT",
    "name": "Lithuania"
}, {"id": "LU", "name": "Luxembourg"}, {"id": "MO", "name": "Macao"}, {"id": "MK", "name": "Macedonia"}, {
    "id": "MG",
    "name": "Madagascar"
}, {"id": "MW", "name": "Malawi"}, {"id": "MY", "name": "Malaysia"}, {"id": "MV", "name": "Maldives"}, {
    "id": "ML",
    "name": "Mali"
}, {"id": "MT", "name": "Malta"}, {"id": "MH", "name": "Marshall Islands"}, {
    "id": "MR",
    "name": "Mauritania"
}, {"id": "MU", "name": "Mauritius"}, {"id": "MX", "name": "Mexico"}, {
    "id": "FM",
    "name": "Micronesia, Federated States Of"
}, {"id": "MD", "name": "Moldova"}, {"id": "MC", "name": "Monaco"}, {"id": "MN", "name": "Mongolia"}, {
    "id": "ME",
    "name": "Montenegro"
}, {"id": "MA", "name": "Morocco"}, {"id": "MZ", "name": "Mozambique"}, {"id": "NA", "name": "Namibia"}, {
    "id": "NL",
    "name": "Netherlands"
}, {"id": "NC", "name": "New Caledonia"}, {"id": "NZ", "name": "New Zealand"}, {
    "id": "NI",
    "name": "Nicaragua"
}, {"id": "NE", "name": "Niger"}, {"id": "NG", "name": "Nigeria"}, {"id": "NU", "name": "Niue"}, {
    "id": "NF",
    "name": "Norfolk Island"
}, {"id": "NO", "name": "Norway"}, {"id": "OM", "name": "Oman"}, {"id": "PK", "name": "Pakistan"}, {
    "id": "PA",
    "name": "Panama"
}, {"id": "PG", "name": "Papua New Guinea"}, {"id": "PY", "name": "Paraguay"}, {
    "id": "PE",
    "name": "Peru"
}, {"id": "PH", "name": "Philippines"}, {"id": "PL", "name": "Poland"}, {"id": "PT", "name": "Portugal"}, {
    "id": "QA",
    "name": "Qatar"
}, {"id": "RO", "name": "Romania"}, {"id": "RU", "name": "Russian Federation"}, {
    "id": "RW",
    "name": "Rwanda"
}, {"id": "WS", "name": "Samoa"}, {"id": "SM", "name": "San Marino"}, {
    "id": "ST",
    "name": "Sao Tome And Principe"
}, {"id": "SA", "name": "Saudi Arabia"}, {"id": "SN", "name": "Senegal"}, {"id": "RS", "name": "Serbia"}, {
    "id": "SC",
    "name": "Seychelles"
}, {"id": "SL", "name": "Sierra Leone"}, {"id": "SG", "name": "Singapore"}, {
    "id": "SK",
    "name": "Slovakia"
}, {"id": "SI", "name": "Slovenia"}, {"id": "SB", "name": "Solomon Islands"}, {
    "id": "SO",
    "name": "Somalia"
}, {"id": "ZA", "name": "South Africa"}, {"id": "ES", "name": "Spain"}, {"id": "LK", "name": "Sri Lanka"}, {
    "id": "SD",
    "name": "Sudan"
}, {"id": "SR", "name": "Suriname"}, {"id": "SZ", "name": "Swaziland"}, {"id": "SE", "name": "Sweden"}, {
    "id": "CH",
    "name": "Switzerland"
}, {"id": "SY", "name": "Syrian Arab Republic"}, {"id": "TW", "name": "Taiwan"}, {
    "id": "TJ",
    "name": "Tajikistan"
}, {"id": "TZ", "name": "Tanzania"}, {"id": "TH", "name": "Thailand"}, {"id": "TL", "name": "Timor-Leste"}, {
    "id": "TG",
    "name": "Togo"
}, {"id": "TK", "name": "Tokelau"}, {"id": "TO", "name": "Tonga"}, {"id": "TN", "name": "Tunisia"}, {
    "id": "TR",
    "name": "Turkey"
}, {"id": "TM", "name": "Turkmenistan"}, {"id": "TV", "name": "Tuvalu"}, {"id": "UG", "name": "Uganda"}, {
    "id": "UA",
    "name": "Ukraine"
}, {"id": "AE", "name": "United Arab Emirates"}, {"id": "GB", "name": "United Kingdom"}, {
    "id": "US",
    "name": "United States"
}, {"id": "UY", "name": "Uruguay"}, {"id": "VU", "name": "Vanuatu"}, {"id": "VE", "name": "Venezuela"}, {
    "id": "VN",
    "name": "Viet Nam"
}, {"id": "WF", "name": "Wallis And Futuna"}, {"id": "YE", "name": "Yemen"}, {
    "id": "ZM",
    "name": "Zambia"
}, {"id": "ZW", "name": "Zimbabwe"}];

export default CountriesList;